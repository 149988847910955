import React, { useState, useEffect, useRef, useMemo } from 'react';
import { getMapAccessKey } from "utils/common";
import { getStopColor, createPopupContent, removeBranding } from 'utils/liveTrackingHelper'
import { useSelector } from 'react-redux';
import { PopupWithImage } from './PopUpWithImage'

var ui;
let bubble;

export const LiveTrackingMap = ({ data, height, hideInfo, routeStops, showStops, routeFollowed, currentLat, currentLon, center, packageStopSequence }) => {
    const mapRef = useRef(null);
    const [map, setMap] = useState(null);
    const { user } = useSelector(
        (state) => state?.auth
    )
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedStop, setSelectedStop] = useState(null);

    const markersArray = []

    const H = window.H;
    // Initialize the HERE Map
    const platform = new H.service.Platform({
        apikey: getMapAccessKey()
    });

    const markerLayer = new H.map.Group();
    const stopsLayerRef = useRef(new H.map.Group()); // Use a ref to persist the same object

    const mapCenter = useMemo(() => {
        if (center?.dcLat && center?.dcLon) {
            return { lat: center?.dcLat, lng: center?.dcLon };
        }
        return null
    }, [center?.dcLat, center?.dcLon]);

    var warehouse = new H.map.Icon("static/images/warehouse.png", { size: { w: 56, h: 56 } });

    const getInitials = (name) => {
        // Split the name by spaces to get individual names
        const names = name?.trim()?.split(' ')?.slice(0, 2);

        // Get the first character of each name and join them together
        const initials = names.map((n) => n.charAt(0).toUpperCase()).join('');
        return initials;
    }

    const createSVGMarkerLabel = (textToShow) => {
        var svgMarkup = '<svg xmlns="http://www.w3.org/2000/svg" width="28" height="35" viewBox="0 0 28 35"><path id="location_on_FILL1_wght400_GRAD0_opsz48" d="M174-845a58.177,58.177,0,0,1-10.522-11.134Q160-861.275,160-865.65a13.534,13.534,0,0,1,4.222-10.456A13.985,13.985,0,0,1,174-880a13.985,13.985,0,0,1,9.778,3.894A13.534,13.534,0,0,1,188-865.65q0,4.375-3.478,9.516A58.178,58.178,0,0,1,174-845Z" transform="translate(-160 880)" fill="#5d48ff"/><text x="14" y="20" font-size="12pt" ' +
            'font-family="Arial" text-anchor="middle" ' +
            'fill="white">' + textToShow + '</text></svg>';
        var icon = new H.map.Icon(svgMarkup);
        return icon;
    }

    const createCarIcon = () => {
        const svgMarkup = `<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none"/><path d="M18.92 6.01C18.72 5.42 18.16 5 17.5 5h-11c-.66 0-1.21.42-1.42 1.01L3 12v8c0 .55.45 1 1 1h1c.55 0 1-.45 1-1v-1h12v1c0 .55.45 1 1 1h1c.55 0 1-.45 1-1v-8l-2.08-5.99zM6.5 16c-.83 0-1.5-.67-1.5-1.5S5.67 13 6.5 13s1.5.67 1.5 1.5S7.33 16 6.5 16zm11 0c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zM5 11l1.5-4.5h11L19 11H5z"/></svg>`
        const icon = new H.map.Icon(svgMarkup)
        return icon;
    }

    // draw  a dom icon
    const createSVGStopLabel = (textToShow, status) => {
        const color = getStopColor(status)
        var svgMarkup = `<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
            <rect stroke="white" fill="${color}" x="1" y="1" width="22" height="22" />
            <text x="12" y="18" font-size="12pt" font-family="Arial" font-weight="bold" text-anchor="middle" fill="white">${textToShow}</text>
        </svg>`;

        var icon = new H.map.Icon(svgMarkup);
        //return new H.map.Icon(div, { size: { w: 56, h: 56 } });
        return icon;

    }

    /**
* Opens/Closes a infobubble
* @param {H.geo.Point} position The location on the map.
* @param {String} text          The contents of the infobubble.
*/
    function openBubble(position, stop) {
        const contentText = createPopupContent(stop, user);
        const showDetailsBtn = contentText.querySelector('#showDetailsBtn');

        // Handle click event on the thumbnail image to show full image
        if (showDetailsBtn) {
            showDetailsBtn.addEventListener('click', () => {
                setSelectedStop(stop)
                setOpenDialog(true);
            });
        }

        if (!bubble) {
            bubble = new H.ui.InfoBubble(
                position,
                { content: contentText });
            ui.addBubble(bubble);
        } else {
            bubble.setPosition(position);
            bubble.setContent(contentText);
            bubble.open();
        }
    }

    function closeBubble() {
        if (bubble) {
            ui?.removeBubble(bubble);
            bubble = null;
        }
    }

    useEffect(() => {

        if (mapCenter) {
            const defaultLayers = platform.createDefaultLayers();
            const hereMap = new H.Map(
                mapRef.current,
                defaultLayers.vector.normal.map,
                {
                    zoom: 9,
                    center: mapCenter
                }
            );

            //add the minimum possible zoom (furthest away possible zoom)
            defaultLayers.vector.normal.map.setMin(2);

            // add a resize listener to make sure that the map occupies the whole container
            window.addEventListener('resize', () => hereMap.getViewPort().resize());

            // hereMap.addObject(group);
            setMap(hereMap);
            // Create the behavior and UI objects
            const behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(hereMap));
            ui = H.ui.UI.createDefault(hereMap, defaultLayers);

            setTimeout(removeBranding, 0);

            // Cleanup function to remove markers when the component is unmounting
            return () => {
                behavior?.dispose();
                ui?.dispose();
                map?.dispose();
            }
        }
    }, [mapCenter]);

    useEffect(() => {
        if (data?.length) {
            let isCoOrdinateExists = false
            map?.addObject(markerLayer); // Add the group to the map's objects
            if (!markersArray?.length) {
                data?.forEach((markerData) => {
                    const { lat, lng, driverName } = markerData;
                    if (lat && lng) {
                        isCoOrdinateExists = true
                        const label = document.createElement('div');
                        label.className = 'marker-label';
                        label.innerHTML = 'Marker Label';
                        const newMarker = new H.map.Marker({ lat: lat, lng: lng }, { icon: createSVGMarkerLabel(getInitials(driverName)) });
                        markersArray.push(newMarker)
                    }
                });
            }

            if (isCoOrdinateExists || markersArray?.length) {
                markerLayer.addObjects(markersArray); // Add all markers to the layer
                map?.getViewModel()?.setLookAtData({
                    bounds: markerLayer?.getBoundingBox()
                });
            }
        }
    }, [data, map]);

    useEffect(() => {
        const existingLayer = map?.getObjects().includes(stopsLayerRef?.current);
        // Remove stop layer
        if (existingLayer) {
            map?.removeObject(stopsLayerRef?.current);
            stopsLayerRef.current.removeAll(); // Optional: Clear objects inside the layer
            stopsLayerRef.current = new H.map.Group(); // Recreate for future use
        }

        //Draw route on Map
        if (routeStops?.length && showStops) {

            let currentLocationMarker = null;
            if (currentLat && currentLon) {
                const markerId = 'currentLocationMarker';

                currentLocationMarker = new H.map.Marker({ lat: currentLat, lng: currentLon }, { icon: createCarIcon() });
                currentLocationMarker.setData({ id: markerId }); // Set ID for marker
                map?.addObject(currentLocationMarker);
            }

            let isMarkerVisible = true;
            setInterval(() => {
                isMarkerVisible = !isMarkerVisible;
                currentLocationMarker?.setVisibility(isMarkerVisible);
            }, 1000); // Change the blinking interval as needed (in milliseconds)

            // let ori = "43.586910,-79.645350";
            let ori = `${mapCenter?.lat},${mapCenter?.lng}`;
            const warehouseMarker = new H.map.Marker({ lat: mapCenter?.lat, lng: mapCenter?.lng }, { icon: warehouse })

            stopsLayerRef?.current?.addObjects([warehouseMarker]);
            warehouseMarker.setData({ hideDetails: true });

            routeStops?.forEach(stop => {

                if (packageStopSequence && (stop?.routeStopSequence > packageStopSequence)) {
                    return
                }
                else {
                    let dest = stop?.lat + ',' + stop?.lon;
                    //inducing forced delay
                    calculateRoute(map, ori, dest)

                    const marker = new H.map.Marker({ lat: stop.lat, lng: stop.lon }, { icon: createSVGStopLabel(stop?.routeStopSequence, stop?.routeStopStatus) });
                    marker?.setData(stop); // Associate stop data with the marker
                    stopsLayerRef?.current?.addObject(marker);
                    if (hideInfo && packageStopSequence && (stop?.routeStopSequence !== packageStopSequence)) {
                        marker.setData({ hideDetails: true });
                    }
                    ori = dest;
                }
            });

            // Add event listener outside the loop
            stopsLayerRef?.current?.addEventListener('tap', function (evt) {
                const clickedMarker = evt.target;

                // Get the stop data from the clicked marker
                const clickedStop = evt.target.getData();
                if (clickedMarker && clickedStop?.hideDetails) {
                    return;
                }
                openBubble(evt.target.getGeometry(), clickedStop);
            });

            map?.addObject(stopsLayerRef?.current);

            // Close bubble when clicking anywhere on the map
            map?.addEventListener('tap', function (evt) {
                if (!(evt.target instanceof H.map.Marker)) {
                    closeBubble();
                }
            });

            map?.getViewModel()?.setLookAtData({
                bounds: stopsLayerRef?.current?.getBoundingBox()
            });

        }
    }, [showStops, routeStops, map]);

    useEffect(() => {
        //Functionality to show exact path that driver follows
        try {

            if (routeFollowed?.length && showStops) {            // Create a line string to store the coordinates
                const existingPolyline = map?.getObjects()?.find(obj => obj.getData && obj.getData()?.id === 'driverRoutePolyline');

                if (existingPolyline) {
                    map.removeObject(existingPolyline);
                }

                const lineString = new H.geo.LineString();
                routeFollowed?.forEach(coord => {
                    lineString.pushPoint(new H.geo.Point(coord?.lat, coord?.lon), { timestamp: coord?.createdAt });
                });

                // Create a polyline and add it to the map
                const polyline = new H.map.Polyline(lineString, {
                    style: {
                        lineWidth: 4,
                        strokeColor: 'red',
                    },
                    data: {
                        id: 'driverRoutePolyline', // Unique identifier for the polyline
                    }
                });

                map?.addObject(polyline);
            }
        }
        catch (error) {
            // Handle the error for this useEffect
            console.log('An error occured', error)
        }
    }, [routeFollowed, showStops])

    //Calculate Route ISO line for Map layover
    const calculateRoute = (map, origin, destination) => {
        const hereRouter = platform.getRoutingService(null, 8);
        var calculateRouteParams = {
            'origin': origin,
            'destination': destination,
            'transportMode': 'car',
            'routingMode': 'fast',
            'departureTime': 'any', //means traffic disabled
            'return': 'polyline,turnByTurnActions,actions,instructions,travelSummary'
        },
            onResult = function (result) {
                var route = result?.routes[0],
                    routeSections = route?.sections;

                routeSections?.forEach(function (section) {
                    // decode LineString from the flexible polyline
                    let linestring = H.geo.LineString.fromFlexiblePolyline(section.polyline);
                    // Create a polyline to display the route:
                    let polyline = new H.map.Polyline(linestring, {
                        style: {
                            lineWidth: 4,
                            strokeColor: 'rgba(0, 128, 0, 0.7)'
                        }
                    });
                    stopsLayerRef?.current?.addObject(polyline);

                    // Listen for the viewchangeend event to ensure the map has finished rendering
                    map?.addEventListener('viewchangeend', function () {
                        // Zoom the map to fit the entire route
                        map?.getViewModel().setLookAtData({
                            bounds: polyline?.getBoundingBox()
                        });
                    });

                });


            },
            onError = function (error) {
                console.log(error);
            }
        hereRouter.calculateRoute(calculateRouteParams, onResult, onError);
    }

    const handlePopupClose = () => {
        setOpenDialog(false)
        setSelectedStop(null)
    }

    return (
        <div>
            <div ref={mapRef} style={{ width: '100%', height: height || '70vh' }}></div>
            {openDialog ? <PopupWithImage routeStop={selectedStop} open={openDialog} onClose={handlePopupClose} /> : null}
        </div>);
};