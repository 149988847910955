import React from 'react'
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/system';
import { allowPackageEdit, canUpdateSignatureOnlyAttribute } from 'utils/packageHelper';

const CustomChip = styled(Chip)({
    fontSize: 14,
    fontFamily: 'Degular, sans-serif'
});


export const DeliveryStatusCellRenderer = ({ allowEditChip = false, onClick, isHighPriority, isSLA, isHighValueItem, parcel, user, handleOnHold, isOnHold, allowOnHoldEdit = false, handleSignatureAttribute, isSignatureRequired }) => {
    const isOrderBatchReady = allowPackageEdit(parcel?.packageStatus, user);
    const isAllowToUpdateSignatureOnlyAttribute = canUpdateSignatureOnlyAttribute(parcel, user)

    const chips = []
    if (allowEditChip && isOrderBatchReady) {
        chips.push({
            label: 'High Priority',
            icon: isHighPriority ? <CloseIcon /> : <AddIcon />,
            onDelete: () => onClick(parcel, 'highPriority', !isHighPriority),
        });
        chips.push({
            label: 'SLA',
            icon: isSLA ? <CloseIcon /> : <AddIcon />,
            onDelete: () => onClick(parcel, 'sla', !isSLA),
        });  
    }
    else {
        if (isHighPriority) {
            chips.push({ label: 'High Priority' });
        }
        if (isSLA) {
            chips.push({ label: 'SLA' });
        }
        if (isHighValueItem) {
            chips.push({ label: 'High Value' });
        }
        if (isOnHold && !allowOnHoldEdit) {
            chips.push({ label: 'On Hold' });
        }
    }
    
    if (allowOnHoldEdit) {
        chips.push({
            label: 'On Hold',
            icon: isOnHold ? <CloseIcon /> : <AddIcon />,  
            onDelete: () => handleOnHold(parcel, isOnHold ? 'REMOVE_FROM_HOLD' : 'PLACE_ON_HOLD'),  
        });
    }
    
    if (isSignatureRequired) {
        if (isAllowToUpdateSignatureOnlyAttribute) {
            chips.push({
                label: 'Signature Only',
                icon: <CloseIcon />,
                onDelete: () => handleSignatureAttribute(parcel),
            });
        }
        else {
            chips.push({ label: 'Signature Only' });
        }
    }

    return (
        chips?.length ?
            <Stack direction="row" spacing={1}>
                {chips?.map((chip, index) => {
                    return (
                        <CustomChip
                            key={`${index}-${chip.label}`}
                            label={chip.label}
                            color="warning"
                            variant="outlined"
                            size="small"
                            deleteIcon={chip.icon}
                            onDelete={chip.onDelete}
                        />)
                })
                }
            </Stack> : '--'
    );
};