import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Button } from '@mui/material';
import { markedMissingTotes } from 'features/route/routeAction'
import 'component/dailyRoaster/AddDuty.scss'
import { CustomModal } from 'component/common/Modal/CustomModal'

export const EditRoute = ({ isModelOpen, handleClose, route }) => {
    const dispatch = useDispatch();
    const [checkedTotes, setCheckedTotes] = useState([]);
    const scannedAndMissingTotes = [
        ...route?.dailyRouteScanSummaryVM?.scannedToteIds || [],
        ...route?.dailyRouteScanSummaryVM?.missingToteIds || []
    ];
    const unScannedTotes = route?.dailyRouteScanSummaryVM?.toteIds.filter(item => !scannedAndMissingTotes?.includes(item));

    const handleFormSubmit = () => {
        dispatch(markedMissingTotes(checkedTotes))
    }

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        if (checked) {
            setCheckedTotes(prevCheckedTotes => [...prevCheckedTotes, name]);
        } else {
            setCheckedTotes(prevCheckedTotes => prevCheckedTotes.filter(item => item !== name));
        }
    };

    return (
        <CustomModal
            open={isModelOpen}
            onClose={handleClose}
        >
            <div className='add-duty-modal'>
                <div><span className='title'>{'Select Missing Totes'}</span></div>
                <div>
                    <span>Route ID : {route?.dailyRouteScanSummaryVM?.routeId} </span>
                    <FormGroup>
                        {unScannedTotes?.map(toteId => {
                            return (<FormControlLabel key={toteId} control={<Checkbox checked={checkedTotes?.includes(toteId)} onChange={handleCheckboxChange} name={toteId} />} label={toteId} />)
                        })}
                    </FormGroup>
                </div>
                <div className='button-container'>
                    <Button onClick={handleFormSubmit} disabled={!checkedTotes?.length} variant='outlined'>Save</Button>
                    <Button onClick={handleClose} variant='outlined'>Cancel</Button>
                </div>
            </div>
        </CustomModal>
    )

}