import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from "react-router-dom";
import { Link } from '@mui/material';
import { Layout } from 'component/common/Layout/Layout';
import { fetchPackages, getStreetPerfectDetails, markHighPriorityAndSLA, updateReturnPackage, removeSignatureAttribute, markOnHold } from 'features/packages/packagesAction'
import { CardDetail } from 'component/dashboard/cardDetail'
import DataTable from 'component/dataTable/dataTable'
import { ResponseModal } from 'component/common/Modal/ResponseModal'
import { Loader } from 'component/common/Loader/Loader'
import { TableHeader } from 'component/dataTable/tableHeader';
import { TableFilter } from 'component/dataTable/tableFilter';
import { getConstantValue, formatDate, getCurrentDateForFilter, getDateWithTimeStamp, getUserDCOption, getAPIFilterAsPerRole, checkPermission, getGlobalFilter, convertDateRangeToUTC, getFSAZones, iconStyle } from 'utils/common'
import { validateForm, validateStartAndEndDate } from 'utils/formValidator'
import { getDataCentersList } from 'utils/userAccountHelper'
import { packageStatus, packageStatusEnum } from 'constants/packageStatus'
import 'component/userManagement/userAccount/UserAccount.scss'
import { setPackageFilter, resetPackageFilter, resetError } from 'features/packages/packagesSlice'
import { EditPackageAddress } from './EditPackageAddress'
import { resetSuccessState, resteStreetPerfectDetails } from 'features/packages/packagesSlice'
import InfoIcon from '@mui/icons-material/Info';
import { setAuditData } from 'features/audit/auditSlice'
import { Audit } from 'component/audit/audit'
import { Tooltip } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { canEditPackage, getDeliveryStatusValue, canViewInternalComments, getRedirectioURL, hideUpdateStatusIcon, checkOnHoldAndAllowEdit } from 'utils/packageHelper'
import { DeliveryStatusFilter } from './DeliveryStatusFilter'
import { DeliveryStatusCellRenderer } from './deliveryStatusCellRenderer'
import { SpecialInstrucionsCellRenderer } from './SpecialInstructionsCellRenderer'
import { urls } from 'utils/urls'
import useFieldChange from 'hooks/useFieldChange';
import { ConfirmationModal } from 'component/common/Modal/ConfirmationModal';

const formInitialState = {
    consignee: '',
    packageStatus: '',
    startDate: '',
    endDate: '',
    fsaZone: '',
    dcName: '',
    trackingNumber: '',
    routeId: '',
    toteId: '',
    manifestId: '',
    fsaSubZone: ''
}

export const Packages = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { packages, error, loading, packageFilter, packageCount, updatePackageSuccess, streetPerfectDetails } = useSelector(
        (state) => state?.packages
    )
    const { fsaZones } = useSelector(
        (state) => state?.fsaZones
    )
    const { userProfile, user } = useSelector(
        (state) => state?.auth
    )
    const { sla, highPriority } = useSelector(
        (state) => state?.deliveryStatusManagement
    )
    const { dataCenters } = useSelector(
        (state) => state?.userManagement
    )
    const { isMobile } = useSelector(
        (state) => state?.viewport
    )
    const [isRouteIdSet, setRouteIdSet] = useState(false);
    const [modalProps, setModalProps] = useState(null)
    const [formValues, setFormValues, handleFieldChange] = useFieldChange(formInitialState);
    const [formError, setFormErrors] = useState({});
    const [page, setPage] = useState(0);
    const [applyFilter, setApplyFilter] = useState(false);
    const [sorteData, setSortedData] = useState(packages);
    const [updatedColumns, setUpdatedColumns] = useState([]);
    const [selectedPackage, setSelectcedPackage] = useState(null);
    const [showAddressUpdateModal, setShowAddressUpdateModal] = useState(false)
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [confirmationModalProps, setConfirmationModalProps] = useState(null);
    const hasEditPermission = checkPermission(user, 'PACKAGES')
    const canGetStreePerfectHelp = checkPermission(user, 'STREET_PERFECT_HELP')
    const hasInternalCommentsAccess = canViewInternalComments(user)
    const params = new URLSearchParams(location.search);
    const routeId = params.get('routeId');

    const fetchPackagesData = () => {
        const filter = getAPIFilterAsPerRole(user, userProfile)
        //Fix for package status filter is not working for CSR
        if (filter?.packageStatus && formValues?.packageStatus) {
            filter['packageStatus'] = formValues?.packageStatus?.value
        }
        const globalFilter = getGlobalFilter(highPriority, sla)
        const { startDate, endDate, ...packageFilterRest } = packageFilter
        const dateFilter = convertDateRangeToUTC(startDate, endDate, 'DD-MM-YYYY')

        dispatch(fetchPackages({
            page: page,
            size: rowsPerPage,
            ...packageFilterRest,
            ...filter,
            ...globalFilter,
            ...dateFilter,
            dcName: getUserDCOption(user)?.value
        }))
    }

    useEffect(() => {
        // Dispatch the action to set the filter in Redux state
        if (routeId) {
            dispatch(setPackageFilter({ 'routeId': routeId }));
            setRouteIdSet(true);
        }
        else {
            setRouteIdSet(true);
        }
    }, [routeId]);

    useEffect(() => {
        if (isRouteIdSet) {
            if (!user?.authorities?.includes('ROLE_CUSTOMER') || userProfile) {
                fetchPackagesData()
            }
        }
    }, [isRouteIdSet, page, applyFilter, rowsPerPage, userProfile, highPriority, sla])

    useEffect(() => {
        return () => {
            // This code will run when the component unmounts
            dispatch(resetPackageFilter())
            dispatch(resetError())
        };
    }, [])

    useEffect(() => {
        //This should be happen only on first load when user come to this widget from  manifest widget by clicking on any status
        if (packageFilter?.packageStatus) {
            const status = packageStatus.find(status => status.value === packageFilter?.packageStatus)
            setFormValues((prevValues) => ({
                ...prevValues,
                'packageStatus': status,
            }));
        }
        if (packageFilter?.manifestId) {
            setFormValues((prevValues) => ({
                ...prevValues,
                'manifestId': packageFilter?.manifestId,
            }));
        }
        if (packageFilter?.routeId || routeId) {
            setFormValues((prevValues) => ({
                ...prevValues,
                'routeId': packageFilter?.routeId,
            }));
        }
        if (packageFilter?.toteId) {
            setFormValues((prevValues) => ({
                ...prevValues,
                'toteId': packageFilter?.toteId,
            }));
        }
        if (packageFilter?.startDate && !formValues?.startDate && !routeId) {
            setFormValues((prevValues) => ({
                ...prevValues,
                startDate: getCurrentDateForFilter(packageFilter?.startDate)
            }));
        }
        if (packageFilter?.endDate && !formValues?.endDate && !routeId) {
            setFormValues((prevValues) => ({
                ...prevValues,
                endDate: getCurrentDateForFilter(packageFilter?.endDate)
            }));
        }
    }, [isRouteIdSet])

    useEffect(() => {
        if (error) {
            setModalProps(
                {
                    title: 'Error Occured!',
                    message: error,
                    open: true,
                    type: error
                }
            )

        }
    }, [error]);

    useEffect(() => {
        if (streetPerfectDetails) {
            setModalProps(
                {
                    title: 'Street Perfect Details',
                    message: streetPerfectDetails,
                    open: true,
                    type: 'success'
                }
            )

        }
    }, [streetPerfectDetails]);

    useEffect(() => {
        if (updatePackageSuccess) {
            setModalProps(
                {
                    title: 'Success',
                    message: 'Parcel Updated Successfully!',
                    open: true,
                    type: 'success'
                }
            )
            const redirectTimer = setTimeout(() => {
                if(selectedPackage){
                    handlePackageEditCick()
                }
                dispatch(resetSuccessState())
                fetchPackagesData()
                setModalProps(null)
            }, 1000);
            return () => {
                clearTimeout(redirectTimer);
            }
        }
    }, [updatePackageSuccess]);

    const handlePageChange = (event, page) => {
        setPage(page)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event?.target?.value, 10));
        setPage(0);
    };

    const trackPackagestatus = (id) => {
        navigate(`${urls?.PARCEL_TRACKING_WIDGET_URL}?tracker-id=${id}`)
    }

    const handleResetFilter = () => {
        setFormValues({
            consignee: '',
            packageStatus: '',
            startDate: '',
            endDate: '',
            fsaZone: '',
            dcName: '',
            trackingNumber: '',
            routeId: '',
            manifestId: '',
            fsaSubZone: ''
        }
        )
        setFormErrors({})
    }

    const handleBackClick = () => {
        dispatch(resetPackageFilter())
        if (routeId) {
            navigate(urls?.LIVE_TRACKING_WIDGET_URL)
        }
        else {
            navigate(-1)
        }
    }

    const handleApplyFilterClick = () => {
        const notRequiredFields = ['consignee', 'packageStatus', 'startDate', 'endDate', 'fsaZone', 'dcName', 'trackingNumber', 'routeId', 'manifestId', 'fsaSubZone']
        const errors = validateForm(formValues, notRequiredFields);
        const dateErrors = validateStartAndEndDate(formValues?.startDate, formValues?.endDate);
        if (Object.keys(errors).length || Object.keys(dateErrors).length) {
            setFormErrors(Object.keys(errors).length ? errors : dateErrors)
        }
        else {
            setFormErrors({})
            const { packageStatus, dcName, fsaZone, startDate, endDate, consignee, trackingNumber, manifestId, routeId, fsaSubZone } = formValues
            const data = []
            if (consignee) {
                data['consignee'] = consignee?.trim()
            }
            if (packageStatus) {
                data['packageStatus'] = packageStatus?.value
            }
            if (dcName?.value) {
                data['dcName'] = dcName?.value || null
            }
            if (fsaZone?.label) {
                data['fsaZoneName'] = fsaZone?.label || null
            }
            if (startDate) {
                data['startDate'] = formatDate(startDate) || null
            }
            if (endDate) {
                data['endDate'] = formatDate(endDate) || null
            }
            if (manifestId) {
                data['manifestId'] = typeof manifestId === 'string' ? manifestId?.trim() : manifestId;
            }
            if (routeId) {
                data['routeId'] = typeof routeId === 'string' ? routeId?.trim() : routeId;
            }
            if (trackingNumber) {
                data['trackingNumber'] = trackingNumber?.trim()
            }
            if (fsaSubZone) {
                data['fsaSubZone'] = fsaSubZone?.trim()
            }
            dispatch(setPackageFilter(data))
            setApplyFilter(!applyFilter)
            setPage(0)
        }
    }

    const handlePackageEditCick = (data) => {
        if (!showAddressUpdateModal) {
            setSelectcedPackage(data)
            setShowAddressUpdateModal(true)
        }
        else {
            setSelectcedPackage(null)
            setShowAddressUpdateModal(false)
        }
    }

    const handleHelpClick = (selectedPackage) => {
        const formData = {
            trackingNumber: selectedPackage?.trackingNumber,
            addressOne: selectedPackage?.addressOne,
            addressTwo: selectedPackage?.addressTwo,
            city: selectedPackage?.city,
            province: selectedPackage?.province,
            postalcode: selectedPackage?.postalCode
        }
        dispatch(getStreetPerfectDetails(formData))
    }

    const handleNavigation = (parcel) => {
        const redirectionURL = getRedirectioURL(parcel)
        navigate(redirectionURL)
    }

    const handleEditDeliveryStatus = (parcel, type, value) => {
        dispatch(markHighPriorityAndSLA({
            trackingNumber: parcel?.trackingNumber,
            type: type,
            value: value
        }))
    }

const handleUpdateStatusClick = (trackingNumber) => {
    if (trackingNumber) {
        setConfirmationModalProps({
            title: 'Send To Investigation',
            message: 'Are you sure this Order requires investigation?',
            open: true,
            onConfirm: () => updateStatus(trackingNumber),
            selectedId: trackingNumber,
            onClose: handleConfirmationClose
        });
    }
};

    const updateStatus = (trackingNumber) => {
    dispatch(updateReturnPackage({ trackingNumbers: [trackingNumber], returnStatus: 'RTERMINAL_INVTGN', widget: 'PARCELS' }));
    setConfirmationModalProps(null)
}

    const handleConfirmationClose = () => {
        setConfirmationModalProps(null)
    }

    const handleSignatureAttribute = (parcel) => {
        dispatch(removeSignatureAttribute({
            trackingNumber: parcel?.trackingNumber,
        }))
    }

    const handleOnHoldClick = (parcel, value) => {
        dispatch(markOnHold({
            trackingNumber: [parcel?.trackingNumber],
            action: value,
            widget: 'PARCELS'
        }))
    }

    const columns = [{
        accessor: "packageDetailsId",
        title: "ID",
        width: "2rem",
        Cell: ({ cell: { value } }) => value || '--',
        render: (item) => item?.packageDetailsId
    },
    {
        accessor: "trackingNumber",
        title: "Tracking Number",
        width: "3rem",
        Cell: ({ cell: { value } }) => <Link id='trackingNumber' to='/system-routes' sx={{ textDecoration: 'none' }}
            style={iconStyle} onClick={() => trackPackagestatus(value)}> {value}</Link > || '--',
        render: (item) => item?.trackingNumber || '--'
    },
    {
        accessor: 'packageStatus',
        title: "Parcel Status",
        width: "5rem",
        Cell: ({ cell: { value } }) => value ? getConstantValue(packageStatus, value) : '--',
        render: (item) => item?.packageStatus ? getConstantValue(packageStatus, item?.packageStatus) : '--'
    },
    {
        accessor: 'deliveryStatus',
        title: "Priority / SLA Violation / High Value / On Hold",
        width: "5rem",
        Cell: ({ cell: { row: { original } } }) => {
            const { isOnHold, allowEdit } = checkOnHoldAndAllowEdit(original, packageStatusEnum, user);
            
            return (
                <DeliveryStatusCellRenderer
                    key={original?.dailyRouteScanSummaryVM?.routeId}
                    isHighPriority={original?.highPriority}
                    isSLA={original?.sla}
                    isHighValueItem={original?.highValueItem}
                    onClick={handleEditDeliveryStatus}
                    allowEditChip={true}
                    parcel={original}
                    user={user}
                    isSignatureRequired={original?.signatureRequired}
                    isOnHold={isOnHold}
                    handleOnHold={handleOnHoldClick}
                    allowOnHoldEdit={allowEdit}
                    handleSignatureAttribute={handleSignatureAttribute}
                />
            );
        },
        render: (item) => getDeliveryStatusValue(
            item?.highPriority, item?.sla, item?.highValueItem, item?.onHold, item?.isSignatureRequired
        ),
        isSortable: false
    },
    {
        accessor: "fsaZone",
        title: "FSA Zone",
        width: "4rem",
        Cell: ({ cell: { value } }) => value || '--',
        render: (item) => item?.fsaZone || '--'
    },
    {
        accessor: "fsaSubZone",
        title: "FSA Sub Zone",
        width: "4rem",
        Cell: ({ cell: { value } }) => value || '--',
        render: (item) => item?.fsaSubZone || '--'
    },
    {
        accessor: "dcName",
        title: "DC Name",
        width: "4rem",
        Cell: ({ cell: { value } }) => value || '--',
        render: (item) => item?.dcName || '--'
    },
    {
        accessor: "manifestId",
        title: "Manifest ID",
        width: "4rem",
        Cell: ({ cell: { value } }) => value || '--',
        render: (item) => item?.manifestId || '--'
    },
    {
        accessor: "routeId",
        title: "Batch Id / Route Id",
        width: "4rem",
        Cell: ({ cell: { row: { original } } }) => original?.routeId ? <Link id='routetId' style={{ cursor: 'pointer' }} sx={{ textDecoration: 'none' }} onClick={() => handleNavigation(original)}>{original?.routeId}</Link> : '--',
        render: (item) => item?.routeId || '--'
    },
    {
        accessor: "toteId",
        title: "Tote ID",
        width: "4rem",
        Cell: ({ cell: { value } }) => value || '--',
        render: (item) => item?.toteId || '--'
    },
    {
        accessor: "addressOne",
        title: "Address Line 1",
        width: "6rem",
        Cell: ({ cell: { row: { original } } }) => original?.addressOne || '--',
        render: (item) => item?.addressOne || '--'
    },
    {
        accessor: "addressTwo",
        title: "Address Line 2",
        width: "6rem",
        Cell: ({ cell: { row: { original } } }) => original?.addressTwo || '--',
        render: (item) => item?.addressTwo || '--'
    },
    {
        accessor: "postalCode",
        title: "Postal Code",
        width: "4rem",
        Cell: ({ cell: { row: { original } } }) => original?.postalCode || '--',
        render: (item) => item?.postalCode || '--'
    },
    {
        accessor: "city",
        title: "City",
        width: "6rem",
        Cell: ({ cell: { value } }) => value || '--',
        render: (item) => item?.city || '--'
    },
    {
        accessor: "province",
        title: "Province",
        width: "6rem",
        Cell: ({ cell: { row: { original } } }) => original?.province || '--',
        render: (item) => item?.province || '--'
    },
    {
        accessor: "specialInstructions",
        title: "Special Instructions",
        width: "6rem",
        Cell: ({ cell: { row: { original } } }) => <SpecialInstrucionsCellRenderer data={original} allowEdit={true} type={'specialInstructions'} />,
        render: (item) => item?.specialInstructions || '--'
    },
    hasInternalCommentsAccess && {
        accessor: "internalComment",
        title: "Internal Comments",
        width: "5rem",
        Cell: ({ cell: { row: { original } } }) => <SpecialInstrucionsCellRenderer data={original} allowEdit={true} type={'internalComments'}/>,
        render: (item) => item?.internalComment || '--'
    },
    {
        accessor: "consignee",
        title: "Consignee",
        width: "4rem",
        Cell: ({ cell: { value } }) => value || '--',
        render: (item) => item?.consignee || '--'
    },
    {
        accessor: "inductionDate",
        title: "Induction Date",
        width: "4rem",
        Cell: ({ cell: { value } }) => value ? getDateWithTimeStamp(value, user) : '--',
        render: (item) => item?.inductionDate ? getDateWithTimeStamp(item?.inductionDate, user) : '--'
    },
    {
        accessor: "createDate",
        title: "Create Date",
        width: "4rem",
        Cell: ({ cell: { value } }) => value ? getDateWithTimeStamp(value, user) : '--',
        render: (item) => item?.createDate ? getDateWithTimeStamp(item?.createDate, user) : '--'
    },
    {
        accessor: "scheduledDeliveryDate",
        title: "Estimated Delivery Date",
        width: "4rem",
        Cell: ({ cell: { value } }) => value ? getDateWithTimeStamp(value, user) : '--',
        render: (item) => item?.scheduledDeliveryDate ? getDateWithTimeStamp(item?.scheduledDeliveryDate, user) : '--'
    },
    hasEditPermission && {
        accessor: 'action',
        isSortable: false,
        title: "Action",
        width: "4rem",
        Cell: ({ cell: { row: { original } } }) => <div className='action' > {canEditPackage(original?.packageStatus) ? <Tooltip title="Edit"><img src="static/images/edit-icon.svg" alt='edit' onClick={() => handlePackageEditCick(original)} /></Tooltip> : null}
            <Tooltip title="Info"><InfoIcon style={iconStyle} fontSize="small" onClick={() => dispatch(setAuditData({ 'entityId': original?.trackingNumber, 'entityType': 'package-details' }))} /></Tooltip>
            {!hideUpdateStatusIcon.includes(original?.packageStatus) && (
            <Tooltip title="Send to Investigation"><ReportProblemIcon style={iconStyle} onClick={() => handleUpdateStatusClick(original?.trackingNumber)}></ReportProblemIcon></Tooltip>)}
            {canGetStreePerfectHelp && original?.packageStatus === 'PROCESSED_STREET_PERFECT_MARKED_BAD' ? <Tooltip title="Help"><HelpOutlineIcon style={iconStyle} fontSize="small" onClick={() => handleHelpClick(original)} /></Tooltip> : null}
        </div>
    }
    ]?.filter(Boolean); // Filter out falsy values (null, undefined, false)

    const tableFilterProps = {
        fields: [
            {
                label: 'Tracking Number',
                value: formValues?.trackingNumber,
                handleFieldChange: handleFieldChange,
                type: 'text',
                width: isMobile ? '9rem' : '12rem',
                name: 'trackingNumber',
                error: formError?.trackingNumber,
                placeholder: 'Tracking Number'
            },
            {
                label: 'Consignee',
                value: formValues?.consignee,
                handleFieldChange: handleFieldChange,
                type: 'text',
                width: isMobile ? '9rem' : '12rem',
                name: 'consignee',
                error: formError?.consignee,
                placeholder: 'Cosignee Name'
            },
            {
                label: 'Batch Id / Route Id',
                value: formValues?.routeId,
                handleFieldChange: handleFieldChange,
                type: 'text',
                width: isMobile ? '9rem' : '12rem',
                name: 'routeId',
                error: formError?.routeId,
                placeholder: 'Batch Id / Route Id'
            },
            {
                label: 'Manifest Id',
                value: formValues?.manifestId,
                handleFieldChange: handleFieldChange,
                type: 'text',
                width: isMobile ? '9rem' : '12rem',
                name: 'manifestId',
                error: formError?.manifestId,
                placeholder: 'Manifest Id'
            },
            {
                label: 'Tote Id',
                value: formValues?.toteId,
                handleFieldChange: handleFieldChange,
                type: 'text',
                width: isMobile ? '9rem' : '12rem',
                name: 'toteId',
                error: formError?.toteId,
                placeholder: 'Tote Id'
            },
            {
                label: 'Parcel Status',
                value: formValues?.packageStatus,
                handleFieldChange: handleFieldChange,
                type: 'select',
                width: isMobile ? '9rem' : '12rem',
                options: packageStatus,
                name: 'packageStatus',
            },
            {
                label: 'Start Date',
                value: formValues?.startDate,
                handleFieldChange: handleFieldChange,
                type: 'date',
                width: isMobile ? '9rem' : '12rem',
                name: 'startDate',
                placeholder: 'DD-MM-YYYY',
                error: formError?.startDate,
                max: new Date()?.toISOString()?.split('T')?.[0]
            },
            {
                label: 'End Date',
                value: formValues?.endDate,
                handleFieldChange: handleFieldChange,
                type: 'date',
                width: isMobile ? '9rem' : '12rem',
                name: 'endDate',
                placeholder: 'DD-MM-YYYY',
                error: formError?.endDate,
                max: new Date()?.toISOString()?.split('T')?.[0]
            },
            {
                label: 'FSA Zone',
                value: formValues?.fsaZone,
                handleFieldChange: handleFieldChange,
                type: 'select',
                width: isMobile ? '9rem' : '12rem',
                options: getFSAZones(fsaZones),
                name: 'fsaZone'
            },
            {
                label: 'FSA Sub Zone',
                value: formValues?.fsaSubZone,
                handleFieldChange: handleFieldChange,
                type: 'text',
                width: isMobile ? '9rem' : '12rem',
                name: 'fsaSubZone',
                error: formError?.fsaSubZone,
                placeholder: 'FSA Sub Zone'
            },
            {
                label: 'DC',
                value: getUserDCOption(user),
                handleFieldChange: handleFieldChange,
                type: 'select',
                width: isMobile ? '9rem' : '12rem',
                options: getDataCentersList(dataCenters),
                name: 'dcName',
                isDisabled: true
            }],
        buttons: [
            {
                label: 'Apply',
                variant: 'contained',
                color: 'white',
                backgroundColor: 'black',
                onClick: handleApplyFilterClick
            },
            {
                label: 'Reset',
                variant: 'contained',
                color: 'white',
                backgroundColor: 'blue',
                onClick: handleResetFilter
            }
        ],
        margin: '0px',
        padding: '10px',
        borderRadius: '10px',
    }

    const handleModelClose = () => {
        setModalProps(null)
        dispatch(resetError())
        if (streetPerfectDetails) {
            dispatch(resteStreetPerfectDetails())
        }
    }
    return (
        <Layout headerTitle={'Parcels'} globalFilter={<DeliveryStatusFilter />} handleBackClick={handleBackClick}>
            <CardDetail >
                <div className='container'>
                    <TableHeader columns={updatedColumns} data={sorteData} exportFileName='Parcels' onRefreshClick={handleApplyFilterClick} />
                    <TableFilter {...tableFilterProps} />
                    <div className='content'>
                        <DataTable columns={columns} data={packages || []} isCollpsable={false} showPagination={packages?.length ? true : false} onPageChange={handlePageChange} page={page} totalRowsCount={packageCount} onSortChange={setSortedData} uniqueKey={'packageDetailsId'} setUpdatedColumns={setUpdatedColumns} handleChangeRowsPerPage={handleChangeRowsPerPage} rowsPerPage={rowsPerPage} />
                    </div>
                </div>
            </CardDetail>
            <Audit />
            {modalProps ? <ResponseModal {...modalProps} handleClose={handleModelClose} /> : null}
            {loading ? <Loader isLoading={loading} /> : null}
            {confirmationModalProps ? <ConfirmationModal {...confirmationModalProps} /> : null}
            {showAddressUpdateModal ? <EditPackageAddress isModelOpen={showAddressUpdateModal} handleClose={handlePackageEditCick} selectedPackage={selectedPackage} /> : null}
        </Layout>
    )
}